// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiUrl         : "https://healthwireapi.ukitss.com/api/",
 //apiUrl         : "http://192.168.1.20:3000/api/",
  documentUrl    : "https://healthwireapi.ukitss.com/member/",
  labDocumentUrl :"https://healthwireapi.ukitss.com/laboratory/",
  dicomUrl       : "https://healthwireapi.ukitss.com/dicom/",
  zoomSdk       : "https://healthwireapi.ukitss.com/zoom_sdk/#/",
  prescriptionpdfUrl  : "https://healthwireapi.ukitss.com/prescription_pdfs/",
  profileCompleteMessage : 'Please, Complete Your Profile!',
  meetingLinkByDoctor : "",
  meetingLinkPatient : "",
  signUrl:"https://healthwireapi.ukitss.com/signature/"
}

//  export const environment = {
//    production: false,
//    apiUrl         : "https://api.medwire.in/api/",
//    documentUrl    : "https://api.medwire.in/member/",
//    labDocumentUrl :"https://api.medwire.in/laboratory/",
//    dicomUrl       : "https://app.medwire.in/dicom/",
//    zoomSdk        : "https://app.medwire.in/zoom_sdk/#/",
//    prescriptionpdfUrl  : "https://api.medwire.in/prescription_pdfs/",
//    profileCompleteMessage : 'Please, Complete Your Profile!',
//    meetingLinkByDoctor : "",
//    meetingLinkPatient : "",
//    signUrl:"https://api.medwire.in/signature/"
// };


