export enum Role {
  All          = "All",
  Admin        = "Admin",
  laboratories = "laboratories",
  patient      = "patient",
  Laboratory   = "Laboratory",
  radiology    = "radiology",
  doctor       = "doctor",
  clinic       = "clinic",
  receiptionist ="receiptionist",
  staff         = "staff",
  prescription_writer ="prescription_writer"
}
