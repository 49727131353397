import { RouteInfo } from "./sidebar.metadata";
import { AuthService } from 'src/app/core/service/auth.service';

var currentUser = JSON.parse(localStorage.getItem('currentUser')) ?? { "userid": 'null' };
//console.log('userIDD = ',currentUser);
export const ROUTES: RouteInfo[] = [
  
  {
    path: "",
    title: "MENUITEMS.MAIN.TEXT",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["All"],
    submenu: [],
  },
  // Clinic Modules

  {
    path: "/clinic/dashboard",
    title: "Dashboard",
    moduleName: "clinic",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "",
    title: "Prescription",
    moduleName: "prescription",
    iconType: "material-icons-two-tone",
    icon: "receipt_long",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [
      {
        path: "/clinic/prescription/prescription-header",
        title: "Prescription Header",
        moduleName: "prescription",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/clinic/prescription/prescription-footer",
        title: "Prescription Footer",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "/clinic/doctor/doctor-list",
    title: "Doctor's List",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/patient-management/patient-list",
    title: "Patient Management",
    moduleName: "patient-management",
    iconType: "material-icons-two-tone",
    icon: "groups",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/appointment/appointment-list",
    title: "Appointment Management",
    moduleName: "appointment",
    iconType: "material-icons-two-tone",
    icon: "edit_calendar",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/billing-history",
    title: "Billing History",
    moduleName: "billing-history",
    iconType: "material-icons-two-tone",
    icon: "history",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/price-management/fee-management",
    title: "Doctor’s Fees",
    moduleName: "price-management",
    iconType: "material-icons-two-tone",
    icon: "price_change",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/booking-schedule/doctor-list",
    title: "Doctor’s Schedule",
    moduleName: "booking-schedule",
    iconType: "material-icons-two-tone",
    icon: "calendar_month",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },

  {
    path: "/clinic/staff-management/staff-list",
    title: "Staff Management",
    moduleName: "staff-management",
    iconType: "material-icons-two-tone",
    icon: "groups",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/plan-list",
    title: "Plan Management",
    moduleName: "clinic",
    iconType: "material-icons-two-tone",
    icon: "request_quote",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/notifications/notification-list",
    title: "Notification Management",
    moduleName: "notifications",
    iconType: "material-icons-two-tone",
    icon: "notifications_active",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/insight-monitoring-list",
    title: "Insight Monitoring",
    moduleName: "clinic",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },

  
  // {
  //   path: "/clinic/add-bank-detail",
  //   title: "Add Bank Detail",
  //   moduleName: "clinic",
  //   iconType: "material-icons-two-tone",
  //   icon: "account_balance_wallet",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["clinic"],
  //   submenu: [],
  // },
  {
    path: "/clinic/plan-purchase-history",
    title: "Plan Purchase History",
    moduleName: "clinic",
    iconType: "material-icons-two-tone",
    icon: "request_quote",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["clinic"],
    submenu: [],
  },
  {
    path: "/clinic/insights",
    title: "Insights",
    moduleName: "clinic",
    iconType: "material-icons-two-tone",
    icon: "insights",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: [""],
    submenu: [],
  },

  //Staff and receiptionist Module Routes
  {
    path: "/staff/dashboard",
    title: "Dashboard",
    moduleName: "staff",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["staff", "receiptionist", "prescription_writer"],
    submenu: [],
  },
  {
    path: "/staff/booking-schedule/doctor-list",
    title: "Doctor’s Schedule",
    moduleName: "booking-schedule",
    iconType: "material-icons-two-tone",
    icon: "calendar_month",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["staff", "receiptionist", "prescription_writer"],
    submenu: [],
  },
  // {
  //   path: "/staff/billing-history",
  //   title: "Billing History",
  //   moduleName: "staff",
  //   iconType: "material-icons-two-tone",
  //   icon: "receipt_long",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["staff","receiptionist","prescription_writer"],
  //   submenu: [],
  // },
  {
    path: "/staff/appointment/appointment-list",
    title: "Appointment Management",
    moduleName: "appointment",
    iconType: "material-icons-two-tone",
    icon: "edit_calendar",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["staff", "receiptionist", "prescription_writer"],
    submenu: [],
  },
  {
    path: "/staff/doctor/doctor-list",
    title: "Doctor Management",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["staff", "receiptionist", "prescription_writer"],
    submenu: [],
  },
  {
    path: "/staff/fees/doctor-fees-list",
    title: "Doctor's Fees",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["staff", "receiptionist", "prescription_writer"],
    submenu: [],
  },
  {
    path: "/staff/patient-management/patient-list",
    title: "Patient Management",
    moduleName: "patient-management",
    iconType: "material-icons-two-tone",
    icon: "groups",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["staff", "receiptionist"],
    submenu: [],
  },
  {
    path: "/staff/notifications/notification-list",
    title: "Notifications",
    moduleName: "notifications",
    iconType: "material-icons-two-tone",
    icon: "notifications_active",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["staff", "receiptionist"],
    submenu: [],
  },

  // Doctor Modules
  {
    path: "/doctors/dashboard",
    title: "Dashboard",
    moduleName: "doctors",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  // 
  {
    path: "/doctors/profile-access/patient-list",
    title: "Profile Access",
    moduleName: "profile-access",
    iconType: "material-icons-two-tone",
    icon: "connect_without_contact",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  {
    path: "/doctors/appointments/clinic-list",
    title: "Appointment List",
    moduleName: "appointments",
    iconType: "material-icons-two-tone",
    icon: "ballot",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  // {
  //   path: "/doctors/add-bank-detail",
  //   title: "Add Bank Detail",
  //   moduleName: "doctors",
  //   iconType: "material-icons-two-tone",
  //   icon: "account_balance_wallet",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["doctor"],
  //   submenu: [],
  // },



  // Admin Modules
  {
    path: "",
    title: "MENUITEMS.DASHBOARD.TEXT",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/dashboard/main",
        title: "MENUITEMS.DASHBOARD.LIST.DASHBOARD1",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/dashboard/dashboard2",
        title: "MENUITEMS.DASHBOARD.LIST.DASHBOARD2",
        moduleName: "dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/dashboard/doctor-dashboard",
        title: "MENUITEMS.DASHBOARD.LIST.DOCTOR-DASHBOARD",
        moduleName: "doctor-dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/dashboard/patient-dashboard",
        title: "MENUITEMS.DASHBOARD.LIST.PATIENT-DASHBOARD",
        moduleName: "patient-dashboard",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.APPOINTMENTS.TEXT",
    moduleName: "appointment",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/appointment/viewAppointment",
        title: "MENUITEMS.APPOINTMENTS.LIST.VIEW-APPOINTMENT",
        moduleName: "appointment",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/appointment/bookAppointment",
        title: "MENUITEMS.APPOINTMENTS.LIST.BOOK-APPOINTMENT",
        moduleName: "appointment",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/appointment/edit-ppointment",
        title: "MENUITEMS.APPOINTMENTS.LIST.EDIT-APPOINTMENT",
        moduleName: "appointment",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.DOCTORS.TEXT",
    moduleName: "doctors",
    iconType: "material-icons-two-tone",
    icon: "supervised_user_circle",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/doctors/allDoctors",
        title: "MENUITEMS.DOCTORS.LIST.ALL-DOCTOR",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/doctors/add-doctor",
        title: "MENUITEMS.DOCTORS.LIST.ADD-DOCTOR",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/doctors/edit-doctor",
        title: "MENUITEMS.DOCTORS.LIST.EDIT-DOCTOR",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/doctors/doctor-profile",
        title: "MENUITEMS.DOCTORS.LIST.PROFILE",
        moduleName: "doctors",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.STAFF.TEXT",
    moduleName: "staff",
    iconType: "material-icons-two-tone",
    icon: "people_alt",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/staff/all-staff",
        title: "MENUITEMS.STAFF.LIST.ALL-STAFF",
        moduleName: "staff",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/staff/add-staff",
        title: "MENUITEMS.STAFF.LIST.ADD-STAFF",
        moduleName: "staff",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/staff/edit-staff",
        title: "MENUITEMS.STAFF.LIST.EDIT-STAFF",
        moduleName: "staff",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/staff/staff-profile",
        title: "MENUITEMS.STAFF.LIST.PROFILE",
        moduleName: "staff",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.PATIENTS.TEXT",
    moduleName: "patients",
    iconType: "material-icons-two-tone",
    icon: "face",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/patients/all-patients",
        title: "MENUITEMS.PATIENTS.LIST.ALL-PATIENT",
        moduleName: "patients",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/patients/add-patient",
        title: "MENUITEMS.PATIENTS.LIST.ADD-PATIENT",
        moduleName: "patients",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/patients/edit-patient",
        title: "MENUITEMS.PATIENTS.LIST.EDIT-PATIENT",
        moduleName: "patients",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/patients/patient-profile",
        title: "MENUITEMS.PATIENTS.LIST.PROFILE",
        moduleName: "patients",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.ROOMS.TEXT",
    moduleName: "room",
    iconType: "material-icons-two-tone",
    icon: "hotel",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/room/all-rooms",
        title: "MENUITEMS.ROOMS.LIST.ALLOTED-ROOMS",
        moduleName: "room",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/room/add-allotment",
        title: "MENUITEMS.ROOMS.LIST.NEW-ALLOTMENT",
        moduleName: "room",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/room/edit-allotment",
        title: "MENUITEMS.ROOMS.LIST.EDIT-ALLOTMENT",
        moduleName: "room",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.BILLING.TEXT",
    moduleName: "billing",
    iconType: "material-icons-two-tone",
    icon: "monetization_on",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/billing/bill-list",
        title: "MENUITEMS.BILLING.LIST.BILL-LIST",
        moduleName: "billing",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/billing/add-bill",
        title: "MENUITEMS.BILLING.LIST.ADD-BILL",
        moduleName: "billing",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/billing/invoice",
        title: "MENUITEMS.BILLING.LIST.INVOICE",
        moduleName: "billing",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.RECORDS.TEXT",
    moduleName: "records",
    iconType: "material-icons-two-tone",
    icon: "list_alt",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/records/birth",
        title: "MENUITEMS.RECORDS.LIST.BIRTH-RECORDS",
        moduleName: "records",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/records/death",
        title: "MENUITEMS.RECORDS.LIST.DEATH-RECORDS",
        moduleName: "records",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.AMBULANCE.TEXT",
    moduleName: "ambulance",
    iconType: "material-icons-two-tone",
    icon: "airport_shuttle",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/ambulance/call-list",
        title: "MENUITEMS.AMBULANCE.LIST.AMBULANCE-CALL-LIST",
        moduleName: "ambulance",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/ambulance/list",
        title: "MENUITEMS.AMBULANCE.LIST.AMBULANCE-LIST",
        moduleName: "ambulance",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.PHARMACY.TEXT",
    moduleName: "pharmacy",
    iconType: "material-icons-two-tone",
    icon: "local_pharmacy",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/pharmacy/medicine-list",
        title: "MENUITEMS.PHARMACY.LIST.MEDICINE-LIST",
        moduleName: "pharmacy",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/pharmacy/add-medicine",
        title: "MENUITEMS.PHARMACY.LIST.ADD-MEDICINE",
        moduleName: "pharmacy",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.DEPARTMENTS.TEXT",
    moduleName: "departments",
    iconType: "material-icons-two-tone",
    icon: "group_work",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/departments/department-list",
        title: "MENUITEMS.DEPARTMENTS.LIST.DEPARTMENT-LIST",
        moduleName: "departments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/departments/add-department",
        title: "MENUITEMS.DEPARTMENTS.LIST.ADD-DEPARTMENT",
        moduleName: "departments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "MENUITEMS.INVENTORY.TEXT",
    moduleName: "inventory",
    iconType: "material-icons-two-tone",
    icon: "shopping_bag",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/admin/inventory/item-stock-list",
        title: "MENUITEMS.INVENTORY.LIST.ITEM-STOCK-LIST",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/admin/inventory/issued-items",
        title: "MENUITEMS.INVENTORY.LIST.ISSUED-ITEMS",
        moduleName: "inventory",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  // Doctor Modules
  // {
  //   path: "/doctor/dashboard",
  //   title: "MENUITEMS.DOCTOR.DASHBOARD",
  //   moduleName: "dashboard",
  //   iconType: "material-icons-two-tone",
  //   icon: "space_dashboard",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Doctor"],
  //   submenu: [],
  // },

  {
    path: "/doctor/dashboard",
    title: "MENUITEMS.DOCTOR.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },

  {
    path: "/doctor/visits-page",
    title: "Laboratory Visits",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "event_note",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  {
    path: "doctor/patient-management/patient-list",
    title: "Patient Management",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "event_note",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  
  {
    path: "/doctor/appointment-manage/appointment-list",
    title: "Appointment Management",
    moduleName: "appointment-manage",
    iconType: "material-icons-two-tone",
    icon: "edit_calendar",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  {
    path: "doctor/doctor-management",
    title: "Doctor Management",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "event_note",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  {
    path: "",
    title: "Laboratory Items",
    moduleName: "laboratory-items",
    iconType: "material-icons-two-tone",
    icon: "widgets",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [
      {
        path: "/doctor/laboratory-items/category-list",
        title: "Category List",
        moduleName: "laboratory-items",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/doctor/laboratory-items/test-list",
        title: "Test List",
        moduleName: "laboratory-items",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/doctor/laboratory-items/package-list",
        title: "Package List",
        moduleName: "laboratory-items",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },

    ],
  },
 
  {
    path: "/doctor/promocode/promocode-list",
    title: "Promocode Management",
    moduleName: "promocode",
    iconType: "material-icons-two-tone",
    icon: "discount",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  {
    path: "/doctor/notification/notification-list",
    title: "Notification Management",
    moduleName: "notification",
    iconType: "material-icons-two-tone",
    icon: "notifications_active",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  {
    path: "/doctor/billing-history",
    title: "Billing History",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  {
    path: "/doctor/plan-list",
    title: "Plan Management",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "request_quote",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },

  {
    path: "/doctor/plan-purchase-history",
    title: "Plan Purchase History",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "request_quote",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  {
    path: "/doctor/inslight-monitoring-list",
    title: "Insight Monitoring",
    moduleName: "doctor",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["laboratories"],
    submenu: [],
  },
  
  // {
  //   path: "/doctor/bulk-report",
  //   title: "Upload Bulk Report",
  //   moduleName: "doctor",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["laboratories"],
  //   submenu: [],
  // },
  
  
 
  

  {
    path: "/doctors/lab-management/lab-list",
    title: "Laboratory Management",
    moduleName: "lab-management",
    iconType: "material-icons-two-tone",
    icon: "biotech",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  {
    path: "/doctors/radio-management/radiology-list",
    title: "Radiology Management",
    moduleName: "radio-management",
    iconType: "material-icons-two-tone",
    icon: "group_work",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },
  {
    path: "/doctors/esignature",
    title: "E-signature",
    moduleName: "radio-management",
    iconType: "material-icons-two-tone",
    icon: "border_color",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["doctor"],
    submenu: [],
  },

  // {
  //   path: "/doctor/appointments",
  //   title: "MENUITEMS.DOCTOR.APPOINTMENTS",
  //   moduleName: "appointments",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Doctor"],
  //   submenu: [],
  // },
  // {
  //   path: "/doctor/doctors",
  //   title: "MENUITEMS.DOCTOR.DOCTORS",
  //   moduleName: "doctors",
  //   iconType: "material-icons-two-tone",
  //   icon: "supervised_user_circle",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Doctor"],
  //   submenu: [],
  // },
  // {
  //   path: "/doctor/patients",
  //   title: "MENUITEMS.DOCTOR.PATIENTS",
  //   moduleName: "patients",
  //   iconType: "material-icons-two-tone",
  //   icon: "face",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Doctor"],
  //   submenu: [],
  // },



  // {
  //   path: "/doctor/settings",
  //   title: "MENUITEMS.DOCTOR.SETTINGS",
  //   moduleName: "settings",
  //   iconType: "material-icons-two-tone",
  //   icon: "settings",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Doctor"],
  //   submenu: [],
  // },
  // {
  //   path: "/apps/chat",
  //   title: "MENUITEMS.DOCTOR.CHAT",
  //   moduleName: "apps",
  //   iconType: "material-icons-two-tone",
  //   icon: "chat",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Doctor"],
  //   submenu: [],
  // },


  // Radiology Modules

  {
    path: "/radiology/dashboard",
    title: "Dashboard",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },

  {
    path: "/radiology/radiology-visits",
    title: "Radiology Visits",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "event_note",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
  {
    path: "/radiology/patient-management/patient-list",
    title: "Patient Management",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
  {
    path: "/radiology/appointment-management/appointment-list",
    title: "Appointment Management",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
  {
    path: "/radiology/doctor-management",
    title: "Doctor Management",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
 
  {
    path: "/radiology/radiology-items/category-list",
    title: "Radiology Items",
    moduleName: "radiology-items",
    iconType: "material-icons-two-tone",
    icon: "widgets",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [
      {
        path: "/radiology/radiology-items/category-list",
        title: "Category List",
        moduleName: "radiology-items",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/radiology/radiology-items/test-list",
        title: "Test List",
        moduleName: "radiology-items",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "/radiology/promocode/promocode-list",
    title: "Promocode Management",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
  {
    path: "/radiology/notification/notification-list",
    title: "Notification Management",
    moduleName: "notification",
    iconType: "material-icons-two-tone",
    icon: "notifications_active",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
  {
    path: "/radiology/billing-history",
    title: "Billing History",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
  
  {
    path: "/radiology/plan-list",
    title: "Plan Management",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "request_quote",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
  {
    path: "/radiology/plan-purchase-history",
    title: "Plan Purchase History",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "request_quote",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },
  {
    path: "/radiology/inslight-monitoring-list",
    title: "Insight Monitoring",
    moduleName: "radiology",
    iconType: "material-icons-two-tone",
    icon: "dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["radiology"],
    submenu: [],
  },

 
  // {
  //   path: "/radiology/upload-bulk-report",
  //   title: "Upload Bulk Report",
  //   moduleName: "radiology",
  //   iconType: "material-icons-two-tone",
  //   icon: "assignment",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["radiology"],
  //   submenu: [],
  // },

  
  
  // Patient Modules



  {
    path: "/patient/dashboard/",
    title: "MENUITEMS.PATIENT.DASHBOARD",
    moduleName: "dashboard",
    iconType: "material-icons-two-tone",
    icon: "space_dashboard",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [],
  },


  {
    path: "",
    title: "Upload Documents",
    moduleName: "appointments",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [
      {
        path: "/patient/search-document",
        title: "Search Scanned Document",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/prescription-member",
        title: "Prescription",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/lab-report",
        title: "Lab Reports",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/vacination-report",
        title: "Vaccination Reports",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/procedure-report",
        title: "Procedure Reports",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },

      {
        path: "/patient/discharge-summary",
        title: "Discharge Summary",
        moduleName: "appointments",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/medical-m-bill",
        title: "Medical Bills",
        moduleName: "medicalbill",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },

      {
        path: "/patient/insurance-m-doc",
        title: "Insurance Documents",
        moduleName: "medicalbill",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },

  // {
  //   path: "/patient/symptomsmember",
  //   title: "Add Symptomes ",
  //   moduleName: "patient",
  //   iconType: "material-icons-two-tone",
  //   icon: "group_add",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },

  {
    path: "",
    title: "Set Reminders",
    moduleName: "notifications",
    iconType: "material-icons-two-tone",
    icon: "add_alarm",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [
      {
        path: "/patient/notifications/pre-medicine-member",
        title: "Set Medicine Reminders",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/notifications/lab-test-member",
        title: "Set Vaccination Reminders",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/notifications/book-appointment-member",
        title: "Set Appointment Reminders",
        moduleName: "notifications",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },

    ],
  },


  // {
  //   path: "/patient/profiles",
  //   title: "Profiles",
  //   moduleName: "profiles",
  //   iconType: "material-icons-two-tone",
  //   icon: "group_add",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },

  // {
  //   path: "/patient/add-profile",
  //   title: "Add Profile",
  //   moduleName: "add-profile",
  //   iconType: "material-icons-two-tone",
  //   icon: "group_add",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },

  // {
  //   path: "/patient/edit-user",
  //   title: "Edit Profile",
  //   moduleName: "edit-user",
  //   iconType: "material-icons-two-tone",
  //   icon: "group_add",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },


  {
    path: "",
    title: "Upload Health Vitals",
    moduleName: "patient",
    iconType: "material-icons-two-tone",
    icon: "accessibility",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [

      {
        path: "/patient/hrmeasure-member",
        title: "Heart Rate ",
        moduleName: "patient",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/bpmeasure-member",
        title: "Blood Pressure ",
        moduleName: "patient",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/rrmeasure-member",
        title: "Respiratory Rate ",
        moduleName: "patient",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/osmeasure-member",
        title: "Oxygen Saturation ",
        moduleName: "patient",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/tempmeasure-member",
        title: "Temperature",
        moduleName: "patient",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["patient"],
        submenu: [],
      },
      {
        path: "/patient/bmimeasure-member",
        title: "BMI",
        moduleName: "patient",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: ["patient"],
        submenu: [],
      },

    ],
  },


  {
    path: "/patient/profile-list",
    title: "Family Profile List",
    moduleName: "profile-list",
    iconType: "material-icons-two-tone",
    icon: "group",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [],
  },
  
  // {
  //   path: "/patient/patient-doctor/doctor-list",
  //   title: "Doctor List",
  //   moduleName: "profile-list",
  //   iconType: "material-icons-two-tone",
  //   icon: "group",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["patient"],
  //   submenu: [],
  // },

  {
    path: "/patient/latest-test-report",
    title: "My Test Reports",
    moduleName: "profile-list",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [],
  },

  {
    path: "",
    title: "Book Appointment",
    moduleName: "book-appointment",
    iconType: "material-icons-two-tone",
    icon: "event",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [
      {
        path: "/patient/book-appointment/appointment-list",
        title: "Doctor Appointment",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/book-appointment/laboratory-user-list",
        title: "Laboratory Appointment",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/book-appointment/radio-user-list",
        title: "Radiology Appointment",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      // {
      //   path: "/patient/book-appointment/radio-user-list",
      //   title: "Radiology Appointment",
      //   moduleName: "paediatric-group",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },

    ],
  },

  {
    path: "",
    title: "Booking History",
    moduleName: "booking-history",
    iconType: "material-icons-two-tone",
    icon: "history",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [
      {
        path: "/patient/booking-history/clinic-history",
        title: "Clinic History",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/booking-history/laboratory-history",
        title: "Laboratory History",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/booking-history/radiology-history",
        title: "Radiology History",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },

    ],
  },
  // {
  //   path: "/patient/symptomsmember",
  //   title: "Add Symptoms",
  //   moduleName: "profile-list",
  //   iconType: "material-icons-two-tone",
  //   icon: "coronavirus",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["patient"],
  //   submenu: [],
  // },



  {
    path: "",
    title: "Pediatric Age Group ",
    moduleName: "paediatric-group",
    iconType: "material-icons-two-tone",
    icon: "child_friendly",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [
      {
        path: "/patient/paediatric-group/baby-growth-view-list",
        title: "Child Growth Tracker",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/paediatric-group/add-child",
        title: "Vaccination Chart",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/paediatric-group/milestone-tracker",
        title: "Milestone Tracker",
        moduleName: "paediatric-group",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },

    ],
  },



  // {
  //   path: "/patient/patientdetails",
  //   title: "Patient Details ",
  //   moduleName: "patientdetails",
  //   iconType: "material-icons-two-tone",
  //   icon: "folder_shared",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },





  {
    path: "",
    title: "Pregnant Women",
    moduleName: "pregnant-woman",
    iconType: "material-icons-two-tone",
    icon: "pregnant_woman",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [
      // {
      //   path:  "/patient/pnancysymptomcheck",
      //   title: "Pregnancy symptoms",
      //   moduleName: "patient",
      //   iconType: "",
      //   icon: "",
      //   class: "ml-menu",
      //   groupTitle: false,
      //   badge: "",
      //   badgeClass: "",
      //   role: [""],
      //   submenu: [],
      // },
      {
        path: "/patient/pregnant-woman/pregnent-womenList",
        title: "Pregnancy Timeline",
        moduleName: "pregnant-woman",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/pregnant-woman/safe-tablets-list",
        title: "Safe Tablets ",
        moduleName: "pregnant-woman",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/patient/pregnant-woman/contraindicatedtablet",
        title: "Contraindicated tablets ",
        moduleName: "pregnant-woman",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },

    ],
  },

  // {
  //   path: "/patient/pnancysymptomcheck",
  //   title: "Pregnancy symptoms",
  //   moduleName: "pnancysymptomcheck",
  //   iconType: "material-icons-two-tone",
  //   icon: "pregnant_woman",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },


  {
    path: "/patient/menstruelcalender",
    title: "Menstrual Cycle Calendar",
    moduleName: "menstruelcalender",
    iconType: "material-icons-two-tone",
    icon: "event_note",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [],
  },
  {
    path: "",
    title: "History Notepad",
    moduleName: "notepad",
    iconType: "material-icons-two-tone",
    icon: "assignment",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu:
      [
        {
          path: "/patient/currentmedimemberlist",
          title: "Current Medication",
          moduleName: "currentmedilist",
          iconType: "",
          icon: "",
          class: "ml-menu",
          groupTitle: false,
          badge: "",
          badgeClass: "",
          role: ["patient"],
          submenu: [],
        },


        {
          path: "/patient/pastmedicalmemberlist",
          title: "Medical History",
          moduleName: "pastmedicallist",
          iconType: "",
          icon: "",
          class: "ml-menu",
          groupTitle: false,
          badge: "",
          badgeClass: "",
          role: ["patient"],
          submenu: [],
        },

        {
          path: "/patient/pastsurgmemberlist",
          title: "Surgical History",
          moduleName: "pastsurgerylist",
          iconType: "",
          icon: "",
          class: "ml-menu",
          groupTitle: false,
          badge: "",
          badgeClass: "",
          role: ["patient"],
          submenu: [],
        },
        {
          path: "/patient/healthdairymemberlist",
          title: "Personal Health Diary ",
          moduleName: "healthdiarylist",
          iconType: "",
          icon: "",
          class: "ml-menu",
          groupTitle: false,
          badge: "",
          badgeClass: "",
          role: ["patient"],
          submenu: [],
        },
      ]
  },
  {
    path: "/patient/profile-access",
    title: "Profile Access",
    moduleName: "profile-access",
    iconType: "material-icons-two-tone",
    icon: "group",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["patient"],
    submenu: [],
  },




  // {
  //   path: "/patient/prescriptions",
  //   title: "MENUITEMS.PATIENT.PRESCRIPTIONS",
  //   moduleName: "prescriptions",
  //   iconType: "material-icons-two-tone",
  //   icon: "receipt_long",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },
  // {
  //   path: "/patient/records",
  //   title: "MENUITEMS.PATIENT.MEDICAL-RECORD",
  //   moduleName: "records",
  //   iconType: "material-icons-two-tone",
  //   icon: "restore_page",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },
  // {
  //   path: "/patient/billing",
  //   title: "MENUITEMS.PATIENT.BILLING",
  //   moduleName: "records",
  //   iconType: "material-icons-two-tone",
  //   icon: "receipt",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },
  // {
  //   path: "/apps/chat",
  //   title: "MENUITEMS.PATIENT.CHAT",
  //   moduleName: "apps",
  //   iconType: "material-icons-two-tone",
  //   icon: "chat",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },
  // {
  //   path: "/patient/settings",
  //   title: "MENUITEMS.PATIENT.SETTINGS",
  //   moduleName: "settings",
  //   iconType: "material-icons-two-tone",
  //   icon: "settings",
  //   class: "",
  //   groupTitle: false,
  //   badge: "",
  //   badgeClass: "",
  //   role: ["Patient"],
  //   submenu: [],
  // },

  // Common Modules

  {
    path: "",
    title: "Apps",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    // role: ["Admin", "Doctor"], orignol
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "calendar",
    title: "Calendar",
    moduleName: "calendar",
    iconType: "material-icons-two-tone",
    icon: "event_note",
    class: "",
    groupTitle: false,
    badge: "New",
    badgeClass: "badge bg-blue sidebar-badge float-end",
    // role: ["Admin", "Doctor"], orignol
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "task",
    title: "Task",
    moduleName: "task",
    iconType: "material-icons-two-tone",
    icon: "fact_check",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    // role: ["Admin", "Doctor"], orignol
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "contacts",
    title: "Contacts",
    moduleName: "contacts",
    iconType: "material-icons-two-tone",
    icon: "contacts",
    class: "",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    // role: ["Admin", "Doctor"], orignol
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "",
    title: "Email",
    moduleName: "email",
    iconType: "material-icons-two-tone",
    icon: "email",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    // role: ["Admin", "Doctor"], orignol
    role: ["Admin"],
    submenu: [
      {
        path: "/email/inbox",
        title: "Inbox",
        moduleName: "email",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/email/compose",
        title: "Compose",
        moduleName: "email",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/email/read-mail",
        title: "Read Email",
        moduleName: "email",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "More Apps",
    moduleName: "apps",
    iconType: "material-icons-two-tone",
    icon: "stars",
    class: "menu-toggle",
    groupTitle: false,
    badge: "4",
    badgeClass: "badge bg-orange sidebar-badge float-end",
    role: ["Admin"],
    submenu: [
      {
        path: "/apps/chat",
        title: "Chat",
        moduleName: "apps",
        iconType: "material-icons-two-tone",
        icon: "chat",
        class: "",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/apps/dragdrop",
        title: "Drag & Drop",
        moduleName: "apps",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/apps/contact-grid",
        title: "Contact Grid",
        moduleName: "apps",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/apps/support",
        title: "Support",
        moduleName: "apps",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Widgets",
    moduleName: "widget",
    iconType: "material-icons-two-tone",
    icon: "widgets",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/widget/chart-widget",
        title: "Chart Widget",
        moduleName: "widget",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/widget/data-widget",
        title: "Data Widget",
        moduleName: "widget",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Components",
    moduleName: "",
    iconType: "",
    icon: "",
    class: "",
    groupTitle: true,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [],
  },
  {
    path: "",
    title: "User Interface (UI)",
    moduleName: "ui",
    iconType: "material-icons-two-tone",
    icon: "dvr",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/ui/alerts",
        title: "Alerts",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/badges",
        title: "Badges",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/chips",
        title: "Chips",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/modal",
        title: "Modal",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/buttons",
        title: "Buttons",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/expansion-panel",
        title: "Expansion Panel",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/bottom-sheet",
        title: "Bottom Sheet",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/dialogs",
        title: "Dialogs",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/cards",
        title: "Cards",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/labels",
        title: "Labels",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/list-group",
        title: "List Group",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/snackbar",
        title: "Snackbar",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/preloaders",
        title: "Preloaders",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/progressbars",
        title: "Progress Bars",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/tabs",
        title: "Tabs",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/typography",
        title: "Typography",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/ui/helper-classes",
        title: "Helper Classes",
        moduleName: "ui",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Forms",
    moduleName: "forms",
    iconType: "material-icons-two-tone",
    icon: "subtitles",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/forms/form-controls",
        title: "Form Controls",
        moduleName: "forms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/forms/advance-controls",
        title: "Advanced Controls",
        moduleName: "forms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/forms/form-example",
        title: "Form Examples",
        moduleName: "forms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/forms/form-validation",
        title: "Form Validation",
        moduleName: "forms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/forms/wizard",
        title: "Form Wizard",
        moduleName: "forms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/forms/editors",
        title: "Editors",
        moduleName: "forms",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Tables",
    moduleName: "tables",
    iconType: "material-icons-two-tone",
    icon: "view_list",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/tables/basic-tables",
        title: "Basic Tables",
        moduleName: "tables",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/tables/material-tables",
        title: "Material Tables",
        moduleName: "tables",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/tables/ngx-datatable",
        title: "ngx-datatable",
        moduleName: "tables",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Medias",
    moduleName: "media",
    iconType: "material-icons-two-tone",
    icon: "image_search",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/media/gallery",
        moduleName: "media",
        title: "Image Gallery",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Charts",
    moduleName: "charts",
    iconType: "material-icons-two-tone",
    icon: "insert_chart",
    class: "menu-toggle",
    groupTitle: false,
    badge: "7",
    badgeClass: "badge bg-green sidebar-badge float-end",
    role: ["Admin"],
    submenu: [
      {
        path: "/charts/echart",
        title: "Echart",
        moduleName: "charts",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/charts/apex",
        title: "Apex",
        moduleName: "charts",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/charts/chartjs",
        title: "ChartJS",
        moduleName: "charts",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/charts/ngx-charts",
        title: "Ngx-Charts",
        moduleName: "charts",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/charts/gauge",
        title: "Gauge",
        moduleName: "charts",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Timeline",
    moduleName: "timeline",
    iconType: "material-icons-two-tone",
    icon: "amp_stories",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/timeline/timeline1",
        title: "Timeline 1",
        moduleName: "timeline",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/timeline/timeline2",
        title: "Timeline 2",
        moduleName: "timeline",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Icons",
    moduleName: "icons",
    iconType: "material-icons-two-tone",
    icon: "eco",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/icons/material",
        title: "Material Icons",
        moduleName: "icons",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/icons/font-awesome",
        title: "Font Awesome",
        moduleName: "icons",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Authentication",
    moduleName: "authentication",
    iconType: "material-icons-two-tone",
    icon: "supervised_user_circle",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/authentication/signin",
        title: "Sign In",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/signup",
        title: "Sign Up",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/forgot-password",
        title: "Forgot Password",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/locked",
        title: "Locked",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/page404",
        title: "404 - Not Found",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/authentication/page500",
        title: "500 - Server Error",
        moduleName: "authentication",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Extra Pages",
    moduleName: "extra-pages",
    iconType: "material-icons-two-tone",
    icon: "description",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/extra-pages/profile",
        title: "Profile",
        moduleName: "extra-pages",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/extra-pages/pricing",
        title: "Pricing",
        moduleName: "extra-pages",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/extra-pages/invoice",
        title: "Invoice",
        moduleName: "extra-pages",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/extra-pages/faqs",
        title: "Faqs",
        moduleName: "extra-pages",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/extra-pages/blank",
        title: "Blank Page",
        moduleName: "extra-pages",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Maps",
    moduleName: "maps",
    iconType: "material-icons-two-tone",
    icon: "map",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/maps/google",
        title: "Google Map",
        moduleName: "maps",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
  {
    path: "",
    title: "Multi level Menu",
    moduleName: "multilevel",
    iconType: "material-icons-two-tone",
    icon: "slideshow",
    class: "menu-toggle",
    groupTitle: false,
    badge: "",
    badgeClass: "",
    role: ["Admin"],
    submenu: [
      {
        path: "/multilevel/first1",
        title: "First",
        moduleName: "multilevel",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
      {
        path: "/",
        title: "Second",
        moduleName: "secondlevel",
        iconType: "",
        icon: "",
        class: "ml-sub-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [
          {
            path: "/multilevel/secondlevel/second1",
            title: "Second 1",
            moduleName: "secondlevel",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
          {
            path: "/multilevel/secondlevel/second2",
            title: "Second 2",
            moduleName: "secondlevel",
            iconType: "",
            icon: "",
            class: "ml-sub-sub-menu",
            groupTitle: false,
            badge: "",
            badgeClass: "",
            role: [""],
            submenu: [],
          },
        ],
      },
      {
        path: "/multilevel/first3",
        title: "Third",
        moduleName: "multilevel",
        iconType: "",
        icon: "",
        class: "ml-menu",
        groupTitle: false,
        badge: "",
        badgeClass: "",
        role: [""],
        submenu: [],
      },
    ],
  },
];
